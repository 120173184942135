<template>
  <div class="mt-2 container-fluid">
    <div class="row">
      <div class="col-lg-12 mx-auto">
        <div class="card">
          <div class="card-header">
            <i class="fa fa-copy"></i> Órdenes Pendientes de Facturación   
          </div>
          <div class="card-body">  
            <div class="row">             
              <div class="col-lg-5">   
                <small>Búsqueda:</small>  
                <div class="input-group">
                  <input type="text" v-model="valor" class="form-control form-control-sm" placeholder="Buscar por NIT / CI o nombres..." v-on:keyup.enter="listar()">
                  <a class="btn btn-secondary btn-sm" @click="listar()"><i class="fa fa-search"></i> Buscar</a>
                  <a class="btn btn-secondary btn-sm" @click="valor='';listar()"><i class="fa fa-times"></i></a>
                </div> 
              </div>
            </div>
            <div class="row">             
              <div class="col md-12">
                <div class="card border-primary">                    
                  <div class="card-body">  
                    <div class="table-responsive" style="overflow-y:scroll; height:550px;">
                      <table class="table table-sm table-bordered table-condensed">
                        <thead>
                          <tr class="table-secondary">
                            <th class="text-center">Nro. Orden</th>
                            
                            <th>NIT/CI</th>
                            <th>Razón Social</th>
                            <th>Arancel</th>                            
                            <th>Importe</th>
                            <th>Fecha Registro</th>
                            <th>Acciones</th>
                          </tr>
                        </thead>                          
                        <tbody>
                          <tr v-for="(item, index) in registros" :key="index">
                            <td class="text-center text-primary">{{ item.id_orden }}</td>                           
                            <td>
                              {{item.nitci}}                   
                            </td>
                            <td>
                              {{item.razonsocial}}                   
                            </td>
                            <td>
                              {{item.arancel}}                   
                            </td>
                            <td>
                              {{item.importe}} Bs.-                    
                            </td>
                            <td>
                              {{formatoFecha(item.fecha)}} -
                              <small class="text-info">{{formatoHora(item.fecha)}}</small>
                            </td>     
                            <td>
                              <a v-if="item.estado=='P'" class="btn btn-sm btn-success ml-4" @click="showModal(item)"><i class="fa fa-ticket"></i> Facturación Electrónica</a>
                            </td>                      
                          </tr>
                        </tbody>
                      </table>                        
                    </div>

                    <div class="overflow-auto">
                      <b-pagination
                        class="mb-1 mt-2"
                        v-model="currentPage"
                        :total-rows="rows"
                        :per-page="perPage"
                        first-text="<<"
                        prev-text="<"
                        next-text=">"
                        last-text=">>"
                        size="sm"
                        @change="cambio"
                        v-if="rows>10"
                      ></b-pagination>
                      <small class="text-muted">Página: {{ currentPage}} | Registros: del {{ ((currentPage*perPage) - perPage)+1 }} al {{currentPage*perPage}} | Total:  {{rows}}</small>                       
                    </div>

                  </div>
                </div>

              </div>
            </div>

          </div>
        </div> 
      </div>
    </div>
    <Loading v-show="cargando" />

    <b-modal
        class="modal fade"
        :title="tituloModal"
        size="lg"
        ref="emision"
        hide-footer
        :no-close-on-backdrop="true"
    >
      <template #modal-header="{ close }">                
        <h5>{{tituloModal}}</h5>
      </template>
      <div class="container">
        <div class="panel" v-if="formulario">
          <div class="row">
            <div class="table-responsive">
              <table class="table table-sm table-bordered">
                <thead>
                  <tr>
                    <th class="text-center">Cantidad</th>
                    <th class="text-center">Costo</th>
                    <th>Descripción</th>
                    <th class="text-center">Importe Neto</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="text-center">{{ ordenElegida.cantidad }}</td>
                    <td class="text-center">{{ ordenElegida.costo }} Bs.-</td>
                    <td>{{ ordenElegida.arancel }}</td>
                    <td class="table-info text-center">{{ ordenElegida.importe }} Bs.-</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="row">
            <div class="col-md-5">
              <div class="form-group">
                <small class="col-form-label col-form-label-sm" >Tipo Documento :</small>
                <select
                    class="form-select form-select-sm"                        
                    v-model.trim="$v.nuevaFactura.tipo_documento.$model"                    
                  >
                    <option v-for="(item,index) in tipoDocumentos" :key="index" :value="item.id">{{item.descripcion}}</option>
                </select>
              </div>  
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <small class="col-form-label col-form-label-sm" >NIT/CI:</small>
                <input class="form-control form-control-sm" type="text" placeholder="NIT..." v-model.trim="$v.nuevaFactura.nit.$model">
              </div>  
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <small class="col-form-label col-form-label-sm" >Nombre/Razón Social:</small>
                <input class="form-control form-control-sm" type="text" placeholder="Nombre/Razón Social..." v-model.trim="$v.nuevaFactura.razonsocial.$model">
              </div>  
            </div>            
          </div>
          <div class="row">
            <div class="col-md-7">
              <div class="form-group">
                <small class="col-form-label col-form-label-sm" >Correo:</small>
                <input class="form-control form-control-sm" type="text" placeholder="Correo..." v-model.trim="$v.nuevaFactura.correo.$model">
              </div>  
            </div>
            <div class="col-md-5">
              <div class="form-group">
                <small class="col-form-label col-form-label-sm" >Tipo Depósito:</small>
                <select
                    class="form-select form-select-sm"                        
                    v-model.trim="$v.nuevaFactura.tipo_deposito.$model"                    
                  >
                    <option v-for="(item,index) in tipoDepositos" :key="index" :value="item.descripcion">{{item.descripcion}}</option>
                </select>
              </div>  
            </div>
          </div>
          <div class="card border-primary mt-2" v-if="nuevaFactura.tipo_deposito=='DEPÓSITO'">
            <div class="row p-2">
              <div class="col-md-4">
                <div class="form-group">
                  <small class="col-form-label col-form-label-sm" >Nro. Depósito:</small>
                  <input class="form-control form-control-sm" type="text" placeholder="Nro. Depósito..." v-model.trim="detalle.nro_deposito">
                </div>  
                <div class="form-group">
                  <small class="col-form-label col-form-label-sm" >Banco:</small>
                  <select
                      class="form-select form-select-sm"                        
                      v-model.trim="detalle.id_banco"    
                      @change="obtenerDescripcion($event)"                
                    >
                      <option v-for="(item,index) in bancos" :key="index" :value="item.id_banco">{{item.descripcion}}</option>
                  </select>
                </div>  
                <a class="btn btn-success btn-sm mt-2" @click="agregarBancos()"><i class="fa fa-check"></i> Agregar</a>
              </div>
              <div class="col-md-8">
                <div class="table-responsive">
                  <table class="table table-sm table-bordered">
                    <thead>
                      <tr>
                        <th>Banco</th>
                        <th>Nro.</th>
                        <th>Eliminar</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, index) in detalleBancos" :key="index">
                        <td>{{item.banco}}</td>
                        <td>{{item.nro_deposito}}</td>
                        <td class="text-center">
                            <a class="btn btn-danger btn-sm" @click="quitarDetalle(item)"><i class="fa fa-times"></i></a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>            
          </div>          
          <hr>
          <div class="row">
            <div class="col-md-12 text-end">
              <button class="btn btn-primary" :disabled="$v.$invalid && emisionValida" @click="factura(ordenElegida)"><i class="fa fa-credit-card"></i> Emitir Factura</button>
              <a class="btn btn-secondary" @click="cancelar()"><i class="fa fa-times"></i> Cancelar</a>
            </div>            
          </div>

        </div>
        <div class="panel" v-else>
          <div class="row">
            <div class="col-lg-12" v-if="emitiendo">
              <div class="text-center" >              
                <i class="fa fa-spinner fa-spin fa-5x text-info"></i>
                <h4 class="text-info mt-3">Espere por favor...</h4>
              </div> 
            </div>
            <div class="col-lg-6 my-auto" v-if="!emitiendo">            
              <div class="alert alert-success">
                <p>La solicitud se emitió correctamente. La factura en formato digital llegará al <strong>correo electrónico</strong> del cliente.</p>
                <p>Código Seguimiento: <strong>{{codigoSeguimiento}}</strong> </p>
              </div>           
            </div>
            <div class="col-lg-6 text-center" v-if="!emitiendo">
              <div class="row ">
                <div class="col-lg-7">
                  <img src="../assets/logoAgetic.png" alt="">
                </div>
                <div class="col-lg-5">
                  <i class="fa fa-check fa-3x text-success"></i>                
                </div>
              </div>
              <hr>
              <div class="row">
                <div class="col-lg-7">
                  <img src="../assets/logoSinVer.png" alt="">
                </div>
                <div class="col-lg-5">
                  <i class="fa fa-check fa-3x text-success"></i>                  
                </div>
              </div>              
            </div>
          </div>
          <hr>
          <div class="row">
            <div class="col-lg-12 text-center" v-if="!emitiendo">
              <a class="btn btn-info" @click="hideModal(ordenElegida)">Finalizar</a>
            </div>          
          </div>
        </div>
      </div>
    </b-modal>    

  </div>
</template>

<script>
import { required, minLength , maxLength,helpers, email} from "vuelidate/lib/validators";
import Loading from '@/components/Loading';
import moment from 'moment';

const letras = helpers.regex("alpha", /^[a-zA-ZñÑ ]*$/);

export default {
  data() {
    return {
      cargando:false,
      emitiendo:false,
      registros: [],
      currentPage:1,
      rows:0,
      perPage:10,
      valor:'',
      ordenElegida: {},
      tituloModal:'',
      codigoSeguimiento: '',
      estado:'P',
      formulario: true,
      nuevaFactura:{
        nit:'',
        razonsocial:'', 
        correo:'',
        tipo_deposito:'EFECTIVO',
        tipo_documento: 1
      },
      tipoDepositos:[{descripcion:'EFECTIVO'},{descripcion:'DEPÓSITO'},{descripcion:'CHEQUE'}],
      tipoDocumentos:[{id: 1, descripcion:'DOCUMENTO DE IDENTIDAD'},{id: 5, descripcion:'NIT'}],
      detalle:{
        id_banco:0,
        nro_deposito:''
      },
      detalleBancos:[],
      bancos:[],
      emisionValida:false
    }
  },
  components: {
    Loading    
  },
  
  methods: {
    async listarBancos() {
      this.cargando = true;      
      try {              
        let resultados = await this.axios.get(`/listarBancos`);
        this.bancos = resultados.data.contenido.registros; 
        this.cargando = false;
      } catch (error) {
        this.cargando = false;
        console.log(error);
      }
    },
    async listar() {
      this.cargando = true;      
      try {              
        let resultados = await this.axios.get(`/ordenes/${this.estado}/${this.currentPage}/${this.valor}`);
        this.registros = resultados.data.contenido.registros; 
        this.rows = resultados.data.contenido.total;
        this.cargando = false;
      } catch (error) {
        this.cargando = false;
        console.log(error);
      }
    },
    cambio(value){
      this.currentPage = value;
      this.listar();
    },
    formatoFecha(fecha){
      return moment(fecha).format('DD/MM/YYYY');
    },
    formatoHora(fecha){
      return moment(fecha).format('HH:mm:ss');
    },    
    async factura(ordenElegida){
      this.$v.$touch();
      if(this.$v.$invalid){
        this.cargando = false;
        return;
      }

      if(!this.detalleBancos.length>0 && this.nuevaFactura.tipo_deposito=='DEPÓSITO'){
        this.$alert("Debe agregar al menos un depósito","Atención","error"); 
        return;
      };

      ordenElegida.nitci = this.nuevaFactura.nit;
      ordenElegida.razonsocial = this.nuevaFactura.razonsocial;
      ordenElegida.tipo_deposito = this.nuevaFactura.tipo_deposito;
      ordenElegida.correo = this.nuevaFactura.correo;
      ordenElegida.tipo_documento= this.nuevaFactura.tipo_documento;
      ordenElegida.detalleBancos = this.detalleBancos;

      this.emitiendo=true;
      try {
        let res = await this.axios.post(`/factura`, ordenElegida);      
        this.emitiendo = false;           
        this.codigoSeguimiento = res.data.contenido.datos.codigoSeguimiento;
        this.formulario = false;
      } catch (error) {
        this.emitiendo = false;    
        console.log(error);
      }  
    },
    showModal(item) {
      this.ordenElegida = item;
      this.$refs["emision"].show();
      this.tituloModal=`Emisión de Factura Electrónica | Nro. de Orden: ${item.id_orden}`;    
      this.nuevaFactura.nit = item.nitci;
      this.nuevaFactura.razonsocial = item.razonsocial;
      this.nuevaFactura.correo = item.correo;
      this.nuevaFactura.tipo_deposito= 'EFECTIVO';
      this.detalleBancos=[];
    },
    async hideModal(item) {
      this.cargando = true;  
      try {
        await this.axios.get(`/verificarFactura/${item.id_orden}`);   
        this.$refs["emision"].hide();   
        this.$alert("La factura se emitió correctamente","Correcto","success"); 
        this.listar();
        this.cargando = false; 
      } catch (error) {
        this.cargando = false;    
        console.log(error);
      }  
    },
    cancelar(){
      this.$refs["emision"].hide();  
    },
    agregarBancos(){
      if(this.detalle.id_banco==undefined || this.detalle.id_banco==''){
        this.$alert("Debe elegir un banco","Atención","error"); 
        return;
      };
      if(this.detalle.nro_deposito==undefined || this.detalle.nro_deposito==''){
        this.$alert("Debe ingresar un número de depósito","Atención","error"); 
        return;
      };
      this.detalleBancos.push({
        id_banco:this.detalle.id_banco,
        banco:this.detalle.banco,
        nro_deposito:this.detalle.nro_deposito,
        descripcion:this.detalle.banco
      });
      this.detalle = {};
    },
    obtenerDescripcion(e){
      this.detalle.banco = e.target[event.target.selectedIndex].text;
    },
    quitarDetalle(item){
      this.detalleBancos.splice(this.detalleBancos.indexOf(item), 1);
    }

  },
  created(){
    this.listarBancos();
    this.listar();
  },
  validations: {
    nuevaFactura: {
      nit: { required, minLength: minLength(5), maxLength: maxLength(10) },
      razonsocial: { required, letras, minLength: minLength(3) },      
      tipo_deposito: { required },
      correo:{required, email},
      tipo_documento: {required}
    }
  }
}
</script>
